import React from "react";
import Axios from "axios"
import logo from '../frontend/coverbggg.jpg';
import { useState } from "react";
import { useEffect } from "react";
import Allnewspage from "./allnewspage";
import Paginationnews from "./paginationnews";

function AllNewsComponent(){
        const [ posts ,setData]=useState([])
        const [loading, setLoading]=useState(false)
        const [currentPage, setCurrentPage]=useState(1)
        const [postPerPage]=useState(10)


    useEffect(()=>{
        Axios.get('https://www.epic.org.rw/getpost.php')
        .then(res => {
            setData(res.data);
            setLoading(false)
        })
    },[])

    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    const currentPosts = posts.slice(indexOfFirstPost,indexOfLastPost);

    const paginate = (pageNumber) =>{
        setCurrentPage(pageNumber)
    }
    const paginationNext = (pageNumber) =>{
        setCurrentPage(pageNumber+1)
    }
    const paginationPrevious = (pageNumber) =>{
        setCurrentPage(pageNumber-1)
    }
 
        return(
            <>
           
		
		<div id="fh5co-blog-section" className="fh5co-section-gray">
			<div className="container">
				<div className="row">
					<div className="col-md-8 col-md-offset-2 text-center heading-section">
						<h3>Our Stories</h3>
					</div>
				</div>
			</div>
			<div className="container">
            <Allnewspage posts={currentPosts} loading={loading}/>
            <Paginationnews 
            currentPage={currentPage}
            postsPerPage={postPerPage} 
            totalPosts={posts.length}
            paginate={paginate}
            paginateNext={paginationNext}
            paginatePrevious={paginationPrevious}
            />
			</div>
		</div>
		

            </>
        )
    }

export default AllNewsComponent