import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebookF,faInstagram,faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link,useNavigate} from "react-router-dom";
import { useState } from "react";
import logo from '../frontend/coverbggg.jpg';
import * as actions from "../store/action/auth";
import {connect} from "react-redux";
import logo1 from "../frontend/logoepic.jpeg"

export class FooterComponent extends React.Component{
    render(){
        return(
            <>
            <footer>
			<div id="footer" style={{backgroundColor:"#228B22"}}>
				<div className="container">
					<div className="row">
					{/* <nav>
						<ul>
							<li>
								<a href="/"><FontAwesomeIcon icon="fa fa-home"/> Home</a>
							</li>
							<li><a href="/about"> <FontAwesomeIcon icon="fa fa-info"/> About Us</a></li>
							</ul>
					</nav> */}

						<div className="col-md-6 col-md-offset-3 text-center">
							<p className="fh5co-social-icons">
								<a href="https://www.instagram.com/epic.rwanda/?hl=en"><FontAwesomeIcon  icon={faFacebookF}/></a>
								<a href="#"><FontAwesomeIcon  icon={faTwitter}/></a>
								<a href="#"><FontAwesomeIcon  icon={faInstagram}/></a>
							</p>
							<p>Copyright { new Date().getFullYear()} <a href="#">EPIC</a>. All Rights Reserved. </p>
						</div>
					</div>
				</div>
			</div>
		</footer>
            </>
        )
    }
}