import React from 'react';
import { useState } from 'react';
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "../App.css"
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebookF,faInstagram,faTwitter } from '@fortawesome/free-brands-svg-icons';


const AllAdminProgrampage = ({programd,loading}) =>{
    const [ list, setList] = useState("");
    const [ video, setData] = useState([]);
    let Navigate = useNavigate();

    function onDelete(id){
        //console.log(id)
        Axios.delete(`https://www.epic.org.rw/backend/deleteprogram.php?id=${id}`)
        .then( res =>{
           setData(res.data)
           swal("Good job!", "Program Deleted Successfully!", "success");
           Navigate('/adminprogram');
        }).catch(err => {
           console.log(err)
           swal("try again!", "Program was not Deleted Successfully!", "danger");
           Navigate('/adminprogram');
        })
    }
    if(loading){
        return <h2>Loading...</h2>
    }
    return (
        <>
                     <input type="text" placeholder="search..." onChange={(event) => {setList(event.target.value);}} className="form-control" style={{marginTop: "10px"}} />
     <table id="myTable" className="table table-striped table-bordered" style={{marginTop: "10px"}}>
    <thead>
        <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Status</th>
            <th></th>
            <th></th>
        </tr>
        </thead>
        <tbody>
            {
                    programd.filter((program) => {
                        if(list === ""){
                            return program;
                        }
                        else if(program.name.toLowerCase()
                                .includes(list.toLowerCase())){
                            return program;
                        }else if(program.description.toLowerCase()
                        .includes(list.toLowerCase())){
                            return program;
                        }
    
                    }).map(program => (
            <tr key={program.programid}>
                <td>{program.name}</td>
                <td className="fifty-chars">{program.description}</td>
                <td>
                {program.status == "1" ? "Running" : "Finished"} 
                </td>
                <td><Link to={`/adminprogramupdate/${program.programid}`}><button className="btn btn-primary" title='Update'><FontAwesomeIcon icon="fa fa-edit"/></button></Link></td>
                <td><button onClick={() => onDelete(program.programid)} className="btn btn-primary" title='Delete'><FontAwesomeIcon icon="fa fa-trash"/></button></td>
            
            </tr>
                ))
            }
        </tbody>
 </table> 
 </>
    );
}

export default AllAdminProgrampage;