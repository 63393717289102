import React, { useState } from 'react'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { NewsComponent } from '../components/news';
import NewsComponentDetails  from '../components/newsdetails';
import { AboutComponent } from '../components/about'
import { Home } from '../components/home';
import  GalleryComponent  from '../components/gallery'
import { ProgramComponent } from '../components/program'
import VideoComponent  from '../components/video'
import ContactComponent from '../components/contact'
import LoginComponent from '../components/login';
import RegisterComponent from '../components/register';
import AllNewsComponent from '../components/allnews';
import ProgramComponentDetails  from "../components/programdetails";
import AllProgramComponent from "../components/allprogram";
import AllProgramRunningComponent from '../components/allprogramrunning';
import AllProgramFinishedComponent from '../components/allprogramfinished';
import { ProgramStatusPendingComponent } from '../components/programstatuspending';
import { ProgramStatusFinishedComponent } from '../components/programstatusfinished';
import HeaderComponent from './header'
import VideoComponentDetails from '../components/videodetails';
import Donate from '../components/donate';

export class CustomLayout extends React.Component{
 render(){
    return (
      <>
      <Router forceRefresh={true}>
      <HeaderComponent/>
      <Routes>
      <Route path="" element={<Home/>}/>
        <Route path="/news" element={<NewsComponent/>}/>
        <Route path="/newsdetails/:postid" element={<NewsComponentDetails/>}/>
        <Route path="/allnews" element={<AllNewsComponent/>}/>
        <Route path="/about" element={<AboutComponent />}/>
        <Route path="/gallery" element={<GalleryComponent />}/>
        <Route path="/video" element={<VideoComponent />}/>
        <Route path="/program" element={<ProgramComponent />}/>
        <Route path="/programdetails/:programid" element={<ProgramComponentDetails />}/>
        <Route path="/videodetails/:videoid" element={<VideoComponentDetails />}/>
        <Route path="/programcompleted" element={<ProgramStatusFinishedComponent />}/>
        <Route path="/programrunning" element={<ProgramStatusPendingComponent/>}/>
        <Route path="/allprogram" element={<AllProgramComponent/>}/>
        <Route path="/allprogramrunning" element={<AllProgramRunningComponent/>}/>
        <Route path="/allprogramfinish" element={<AllProgramFinishedComponent/>}/>
        <Route path="/contact" element={<ContactComponent />}/>
        <Route path="/login" element={<LoginComponent />}/>
        <Route path="/Register" element={<RegisterComponent />}/>
        <Route path="/donate" element={<Donate />}/>
      </Routes>
    </Router>
      </>
    );
        }
    }