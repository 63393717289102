import React, {Component} from "react";
import { Link,useNavigate} from "react-router-dom";
import { useState } from "react";
import logo from '../frontend/coverbggg.jpg';
import * as actions from "../store/action/auth";
import {connect} from "react-redux";

function RegisterComponent(props){
	const [username,setUsername] = useState('')
	const [password1, setPassword] = useState('')
	const [email, setEmail] = useState('')
	const [password2, setPassword1] = useState('')
	let Navigate = useNavigate()

    const onSubmit = e =>{
        e.preventDefault();
        console.log("hello")
		props.onAuth(username,email,password1,password2)
		Navigate('/admin')
    }

        return(
            <>

<div id="fh5co-content-section" className="fh5co-section-gray">
			<div className="container">
				<div className="row"  style={{ marginLeft: '200px'}}>
					<div className="col-md-8">
						<div className="fh5co-team text-center">
							<figure>
								<img src={logo}  alt="user"/>
							</figure>
							<div className="contact-info">
				<h2>Admin Sign Up</h2>
			</div>
			<hr/>
            <form onSubmit={onSubmit}>
							<div className="row">
		<div className="col-md-12">
			<div className="contact-form">
				<div className="form-group">
				  <div className="col-md-12" style={{marginBottom: '15px'}}>          
					<input type="text" onChange={(e)=>setUsername(e.target.value)} value={username} className="form-control" id="fname" placeholder="Enter User Name" name="username"/>
				  </div>
				</div>
				<div className="form-group">
				 <div className="col-md-12" style={{marginBottom: '25px'}}>          
					<input type="text" onChange={(e)=>setEmail(e.target.value)} value={email} className="form-control" placeholder="Enter Email" name="email"/>
				  </div>
				</div>
                <div className="form-group">
				 <div className="col-md-12" style={{marginBottom: '25px'}}>          
					<input type="password" onChange={(e)=>setPassword(e.target.value)} value={password1} className="form-control" placeholder="Enter Password" name="password"/>
				  </div>
				</div>
                <div className="form-group">
				 <div className="col-md-12" style={{marginBottom: '25px'}}>          
					<input type="password" onChange={(e)=>setPassword1(e.target.value)} value={password2} className="form-control" placeholder="Confirm Password" name="password2"/>
				  </div>
				</div>
                <div className="contact-info">
                <label className="form-check-label" >
                     Already have an Account!! 
                    </label><p><Link to="/login">Log In</Link></p>
                    
			</div>
				<div className="form-group">        
				  <div className="col-sm-offset-4 col-md-10">
					<button type="submit" className="btn btn-default">Register</button>
				  </div>
				</div>
			</div>
		</div>
	</div>
    </form>
						</div>
					</div>
				
				</div>
			</div>
		</div>
            </>
        )
    
}

const mapStateToProps = (state) =>{
	return{
		loading: state.loading,
		error: state.error
	}
}

const mapDispatchToProps = (dispatch) => {
	return{
		onAuth: (username,email,password1,password2) => dispatch(actions.authSignup(username,email,password1,password2))
	}

}

export default connect(mapStateToProps,mapDispatchToProps)(RegisterComponent)