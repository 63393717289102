import React from "react";
import logo from '../frontend/coverbggg.jpg'; 
import Axios from 'axios';

import { useParams } from 'react-router-dom'
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';


class ProgramComponentDetails extends React.Component{
    state = {
        programs:{}
    }

    componentDidMount(){
        const programid = this.props.params.programid;
        Axios.get(`https://www.epic.org.rw/getprogramid.php?id=${programid}`)
        .then(res => {
            this.setState({
                programs: res.data[0]
            });
            
        })
        // console.log(programid)
    }

    render(){
        return(
            <>
              
             {/* <div className="fh5co-hero">
			<div className="fh5co-overlay"></div>
			<div className="fh5co-cover text-center" data-stellar-background-ratio="0.5" style={{backgroundImage: `url(${logo})`}}>
				<div className="desc">
					<h2>Our <strong> &amp; Program</strong></h2>
				</div>
			</div>

		</div>
		 */}
		<div id="fh5co-blog-section" className="fh5co-section-gray">
			<div className="container">
				<div className="row">
					<div className="col-md-8 col-md-offset-2 text-center heading-section">
						<h3>{this.state.programs.name}</h3>
						<p>{this.state.programs.status == "1" ? "Running" : "Completed"}</p>
					</div>
				</div>
			</div>
			<div className="container">
            <div className="row row-bottom-padded-md">
                       <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="fh5co-blog">
                               <div className="blog-text">
                                    <div className="prod-title">
                                        <p>{this.state.programs.description}</p>
                                    </div>
                                </div> 
                            </div>
                        </div>
				
                </div>
				{/* <div className="row">
					<div className="col-md-4 col-md-offset-4 text-center ">
						<a href="#" className="btn btn-primary btn-lg">Donate</a>
					</div>
				</div> */}

			</div>
		</div>
		

            </>
        )
    }
}

export default (props) => (
    <ProgramComponentDetails
        {...props}
        params={useParams()}
    />
);