import React from 'react';
import AdminProgramFormUpdate from './adminprogramformupdate';

function AdminProgramUpdate(props) {
    return (
        <div>
                  <div id="fh5co-contact" className="">
            <div className="container">
            <div className="row">
            <div className="col-md-12">
    <AdminProgramFormUpdate/>
        </div>
        </div>
        </div>
        </div>
        </div>
    );
}

export default AdminProgramUpdate;