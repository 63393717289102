import React from "react";
import logo from '../frontend/coverbggg.jpg'; 
import Axios from 'axios';

import { useParams, useNavigate} from 'react-router-dom'
import { useState, useEffect } from 'react';
import swal from "sweetalert";


function NewsComponentDetails (){
   const { postid } = useParams();
   const [ posts, setData] = useState([])
   const [ show, setShowComment] = useState(false)
   const [ email, setEmail] = useState('')
   const [ postj, setPostid ] = useState(postid)
   const [ comment, setComment] = useState('')
   const Navigate = useNavigate();
   

    useEffect(()=>{
        Axios.get(`https://www.epic.org.rw/getpostid.php?id=${postid}`)
        .then(res => {
            setData(res.data[0])
        })
       
        
    },[])

    const onSubmit = () =>{
        const postid = parseInt(postj)
        Axios.post('https://www.epic.org.rw/insertcomment.php',{
            email:email,comment:comment,id:postid
        },{headers: {
            'Content-Type': 'multipart/form-data',
        }}).then( res =>{
			//console.log(res.data);
            swal("Thank You!", "Comment sent Successfully!", "success");
			Navigate(`/newsdetails/${postid}`);
			setComment(()=>"");
			setEmail(()=>"")
			
		}).catch(err => {
			//console.log(err)
            swal("Try Again!", "Comment was not sent Successfully!", "danger");
			Navigate(`/newsdetails/${postid}`);
			setComment(()=>"");
			setEmail(()=>"")
		});
    }
        return(
            <>
              
             <div className="fh5co-hero">
			<div className="fh5co-overlay"></div>
			<div className="fh5co-cover text-center" data-stellar-background-ratio="0.5" style={{backgroundImage: `url(/${posts.file})`}}>
				<div className="desc">
					<h2><strong>{posts.title} Story</strong></h2>
                    
				</div>
			</div>

		</div>
		
		<div id="fh5co-blog-section" className="fh5co-section-gray">
			<div className="container">
				<div className="row">
					<div className="col-md-8 col-md-offset-2 text-center heading-section">
						<h3>{posts.title}</h3>
						<p>{posts.headline}</p>
					</div>
				</div>
			</div>
			<div className="container">
            <div className="row row-bottom-padded-md">
                       <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="fh5co-blog">
                               <div className="blog-text">
                                    <div className="prod-title">
                                        <span>{posts.post_date}</span>
                                        <p>{posts.content}</p> 
                                    </div>
                                </div> 
                            </div>
                        </div>
                </div>
                {
                    show ? 
                    
                    <div className="row row-bottom-padded-md">
                        <form>
                            <div className="row" style={{ marginLeft:"110px"}}>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="email" name="email" onChange={(e)=>setEmail(e.target.value)} value={email} className="form-control" placeholder="Email" required/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="hidden" name="postj" onChange={(e)=>setPostid(e.target.value)} value={postj} className="form-control" placeholder="Email"/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea name="comment" onChange={(e)=>setComment(e.target.value)} value={comment} className="form-control" cols="30" rows="7" placeholder="Comment"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button type="button" onClick={onSubmit} className="btn btn-primary">Send Comment</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                   </div>
             
                 : null
                 }
				<div className="row">
					<div className="col-md-4 col-md-offset-4 text-center ">
						<button onClick={()=> setShowComment(true)} className="btn btn-primary btn-lg">Comment</button>
					</div> 
				</div>

			</div>
		</div>
		

            </>
        )
    }

export default NewsComponentDetails;