import React from 'react';
import logo from '../frontend/coverbggg.jpg';
import "../App.css"

const AllProgramRunningPage = ({programs , loading})=>{
    if(loading){
        return <h2>Loading...</h2>
    }
    return (
        <div className="row row-bottom-padded-md">
        { programs.map(program =>(
               <div className="col-lg-3 col-md-3 col-sm-4" key={program.programid}>
                    <div className="fh5co-blog">
                        <a href={`/programdetails/${program.programid}`}><img className="img-responsive" src={logo} alt=""/></a>
                        <div className="blog-text">
                            <div className="prod-title">
                                <h3><a href={`/programdetails/${program.programid}`}>{program.name}</a></h3>
                                <span>{program.status ? "Running" : "Completed"}</span>
                                <p className="fifty-chars">{program.description}</p>
                                <p><a href={`/programdetails/${program.programid}`}>Learn More...</a></p>
                            </div>
                        </div> 
                    </div>
                </div>
            

            )
                )
        }
        
        </div>
    );
}

export default AllProgramRunningPage;