import React from 'react';
import HeaderComponent from './header'
import { CustomLayout } from './layout';
import { AdminLayout } from './adminlayout';
import Adminheader from './adminheader';

function Layouts(props) {
    return (
        <>
            { props.isAuthenticated ?
             <><AdminLayout props={props}/></>
             :
             <><CustomLayout/></>}

        </>
    );
}

export default Layouts;