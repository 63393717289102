import React, {Component} from "react";
import { Link,useNavigate} from "react-router-dom";
import { useState } from "react";
import logo from '../frontend/coverbggg.jpg';
import * as actions from "../store/action/auth";
import {connect} from "react-redux";
import logo1 from "../frontend/EPICO FINAL.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebookF,faInstagram,faTwitter } from '@fortawesome/free-brands-svg-icons';

function HeaderComponent(props){
   
        return (
            <>
			{/* {
				this.props.isAuthenticated ? <h1>hello</h1> : <h1>helll0000</h1>} */}
			
				<header id="fh5co-header-section" className="sticky-banner fh5co-link">
			<div className="container">
				<div className="nav-header">
					<Link to="#" className="js-fh5co-nav-toggle fh5co-nav-toggle dark"><i></i></Link>
					<h1 id="fh5co-logo"><img src={logo1} height={60} width={80}/><Link to="/">EPIC</Link></h1>
				
					<nav id="fh5co-menu-wrap" role="navigation">
						<ul className="sf-menu" id="fh5co-primary-menu">
							<li>
								<Link to="/"> Home</Link>
							</li>
							<li><Link to="/about" style={{color: "#228B22"}}>  About Us</Link></li>
							<li><Link to="/news" style={{color: "#228B22"}}>  News</Link></li>
							<li><Link to="/program" style={{color: "#228B22"}}>Programs</Link>
							<ul className="fh5co-sub-menu">
									<li><Link to="/programrunning" style={{color: "#228B22"}}>Ndi Ejo Program</Link></li>
									<li><Link to="/programcompleted" style={{color: "#228B22"}}>Ipfundo Program</Link></li>
								</ul>
							</li>
							<li>
							<Link to="/gallery" style={{color: "#228B22"}}> Galleries</Link>
								<ul className="fh5co-sub-menu">
									<li><Link to="/gallery" style={{color: "#228B22"}}> Photos</Link></li>
									<li><Link to="/video" style={{color: "#228B22"}}>  Videos</Link></li>
								</ul>
							</li>
							<li><Link to="/contact" style={{color: "#228B22"}}> Contact</Link></li> 
							{/* <li><Link to="/login" style={{color: "#228B22"}}>  Login</Link></li> */}
						</ul> 
					</nav>
				</div>
			</div>
		</header>
      </>
        )
    }

	
const mapDispatchToProps = (dispatch) => {
	return{
		logout: () => dispatch(actions.logout())
	}
	
}

export default connect(mapDispatchToProps)(HeaderComponent)
