import React from 'react';
import { useState } from 'react';
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "../App.css"
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebookF,faInstagram,faTwitter } from '@fortawesome/free-brands-svg-icons';


const AllAdminPostpage = ({postd,loading}) =>{
    const [ list, setList] = useState("");
    const [ video, setData] = useState([]);
    let Navigate = useNavigate();

    function onDelete(id){
        //console.log(id)
        Axios.delete(`https://www.epic.org.rw/backend/deletepost.php?id=${id}`)
        .then( res =>{
           setData(res.data)
           swal("Good job!", "Post Deleted Successfully!", "success");
           Navigate('/adminnews');
        }).catch(err => {
           console.log(err)
           swal("try again!", "Post was not Deleted Successfully!", "danger");
           Navigate("/adminnews")
        })
 
    }
    if(loading){
        return <h2>Loading...</h2>
    }
    return (
        <>
              <input type="text" placeholder="search..." onChange={(event) => {setList(event.target.value);}} className="form-control" style={{marginTop: "10px"}} />
     <table id="myTable" className="table table-striped table-bordered" style={{marginTop: "10px"}} >
    <thead>
        <tr>
            <th>Title</th>
            <th>Headline</th>
            <th>Content</th>
            <th>Image</th>
            <th>Post Date</th>
            <th></th>
            <th></th>
        </tr>
        </thead>
        <tbody>
            {
                     postd.filter((post) => {
                        if(list === ""){
                            return post;
                        }
                        else if(post.title.toLowerCase()
                                .includes(list.toLowerCase())){
                            return post;
                        }else if(post.headline.toLowerCase()
                        .includes(list.toLowerCase())){
                            return post;
                        }
                        else if(post.post_date.toLowerCase()
                        .includes(list.toLowerCase())){
                            return post;
                        }
    
                    }).map(post => (
            <tr key={post.postid}>
                <td>{post.title}</td>
                <td className="fifty-chars">{post.headline}</td>
                <td className="fifty-chars">{post.content}</td>
                <td><img className="img-responsive" src={post.file} alt="" height={20} width={50}/></td>
                <td>{post.post_date}</td>
                <td><Link to={`/adminupdate/${post.postid}`}><button className="btn btn-primary" title='Update'><FontAwesomeIcon icon="fa fa-edit"/></button></Link></td>
                <td><button onClick={() => onDelete(post.postid)} className="btn btn-primary" title='Delete'><FontAwesomeIcon icon="fa fa-trash"/></button></td>
            </tr>
                ))
            }
        </tbody>
 </table> 
 </>
    );
}

export default AllAdminPostpage;