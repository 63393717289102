import React from "react";
import logo from '../frontend/coverbggg.jpg';

export class MessageCompent extends React.Component{
    render(){
        return(
            <>
            <div id="fh5co-content-section" className="fh5co-section-gray">
			{/* <div className="container">
				<div className="row">
					<div className="col-md-8 col-md-offset-2 text-center heading-section animate-box">
						<h3>Leadership</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Velit est facilis maiores, perspiciatis accusamus asperiores sint consequuntur debitis.</p>
					</div>
				</div>
			</div> */}
			<div className="container">
				<div className="row">
					<div className="col-md-11" style={{ marginRight: '5px'}}>
						<div className="fh5co-team text-center animate-box">
							<figure>
								<img src={logo}  alt="user"/>
							</figure>
							<div className="row">
		<div className="col-md-3">
			<div className="contact-info">
				<h2>Contact Us</h2>
				<h4>We would love to hear from you !</h4>
			</div>
		</div>
		<div className="col-md-9">
			<div className="contact-form">
				<div className="form-group">
				  <div className="col-md-10" style={{marginBottom: '5px'}}>          
					<input type="text" className="form-control" id="fname" placeholder="Enter First Name" name="fname" required/>
				  </div>
				</div>
				<div className="form-group">
				 <div className="col-md-10" style={{marginBottom: '5px'}}>          
					<input type="text" className="form-control" id="lname" placeholder="Enter Last Name" name="lname" required/>
				  </div>
				</div>
				<div className="form-group">
				  <div className="col-md-10" style={{marginBottom: '5px'}}>
					<input type="email" className="form-control" id="email" placeholder="Enter email" name="email" required/>
				  </div>
				</div>
				<div className="form-group">
				  <div className="col-md-10" style={{marginBottom: '5px'}}>
					<textarea className="form-control" rows="5" id="comment"></textarea>
				  </div>
				</div>
				<div className="form-group">        
				  <div className="col-sm-offset-2 col-md-10">
					<button type="submit" className="btn btn-default">Submit</button>
				  </div>
				</div>
			</div>
		</div>
	</div>
						</div>
					</div>
				
				</div>
			</div>
		</div>
            </>
        )
    }
}