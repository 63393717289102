import React from 'react';

const Paginationnews = ({postsPerPage,totalPosts,paginate,paginateNext,paginatePrevious,currentPage}) => {
    const pageNumbers = [];
    for(let i = 1;i <= Math.ceil(totalPosts/postsPerPage); i++){
        if(i===4 && totalPosts>7){
            pageNumbers.push(
                <a>...</a>
            );
            i = Math.ceil(totalPosts/postsPerPage)-3;
        }
        pageNumbers.push(i);
    }
    return (
        <div>
            <nav>
                <ul className='pagination'>
                    <li> <a onClick={() => paginatePrevious(currentPage)} className='page-link'>
                                    Previous
                                </a> </li>
                    {
                        pageNumbers.map(number =>(
                            <li key={number} className="page-item">
                                <a onClick={() => paginate(number)} className='page-link'>
                                    {number}
                                </a>
                            </li>
                        ))
                    }
                    <li>  <a onClick={() => paginateNext(currentPage)} className='page-link'>
                                    Next
                                </a></li>
                </ul>
            </nav>
        </div>
    );
}

export default Paginationnews;