import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {Helmet} from "react-helmet";

import HeaderComponent from './containers/header'
import { CustomLayout } from './containers/layout';
import { AdminLayout } from './containers/adminlayout';
import { FooterComponent } from './containers/footer';
import { connect, useDispatch } from 'react-redux';
import Layouts from './containers/layouts';
import Adminheader from './containers/adminheader';

import * as actions from './store/action/auth'

class App extends React.Component {
  componentDidMount(){
    this.props.onTryAutoSignup();
  }
  render(){
  return (
    <>
      {/* <CustomLayout><HeaderComponent/></CustomLayout> */}
      
      <Layouts {...this.props}/>
      <FooterComponent/>
    </>
  );
}
}
const mapStateToProps = state =>{
  return{
    isAuthenticated:state.token !==null
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{
    onTryAutoSignup: () => {dispatch(actions.authCheckState())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
