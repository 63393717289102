import React from "react";
import Axios from "axios";
import Moment from 'moment'
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function AdminPostForm (){
    const [ show , setShow] = useState(false)
    const [ title , setTitle] = useState('')
    const [ headline , setHeadline] = useState('')
    const [ details, setDetails] = useState('')
    const [ file, setFile] = useState(null)
    const [ postd, setData] = useState([])
    const date = Moment().format('YYYY-MM-DD')
    const Navigate = useNavigate();

    //let formData = new FormData();

    useEffect(()=>{
        Axios.get('https://www.epic.org.rw/backend/getpost.php')
        .then( res =>{
           setData(res.data)
        }).catch(err => {
            console.log(err)
        })
    },[])

    const onSubmit = (e) =>{
        e.preventDefault()
        let formData = new FormData();
        formData.append('title', title);
        formData.append('headline', headline);
        formData.append('content', details);
        formData.append('post_date', date);
        formData.append('file', file);

        // let files = [];
        let titles = formData.get('title')
        let headlines = formData.get('headline')
        let contents = formData.get('content')
        let dates = formData.get('post_date')

         Axios.post('https://www.epic.org.rw/backend/insertpost.php',{
           title:titles,headline:headlines,
           content:contents,post_date:dates,
           file:formData.get('file')
         },{headers: {
            'Content-Type': 'multipart/form-data',
        }})
        .then( res =>{
           console.log(res.data)
           swal("Good job!", "Post Posted Successfully!", "success");
			Navigate('/admin');
			setTitle(()=>"");
			setHeadline(()=>"");
            setDetails(()=>"");
        }).catch(err => {
            console.log(err)
            swal("try again!", "Post was not Posted Successfully!", "danger");
            Navigate('/admin');
			setTitle(()=>"");
			setHeadline(()=>"");
            setDetails(()=>"");
        })
    }
        return(
            <>
               <div id="fh5co-contact" className="">
             <div className="container">
                <br/>
               <br/>
               
                 <form onSubmit={onSubmit}>
                     <div className="row">
                         <div className="col-md-6">
                             <h3 className="section-title" style={{marginTop:'15px'}}>Add Stories</h3>
                         </div>
                         <div className="col-md-6">
                          <div className="row"> 
                             <div className="col-md-6">
									<div className="form-group">
										<input type="text" name="name" onChange={(e) => setTitle(e.target.value)} value={title} className="form-control" placeholder="Title"/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<input type="text" name="headline" onChange={(e) => setHeadline(e.target.value)} value={headline} className="form-control" placeholder="Headline"/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<textarea name="details" onChange={(e) => setDetails(e.target.value)} value={details} className="form-control" cols="30" rows="7" placeholder="Details"></textarea>
									</div>
								</div>
                                 <div className="col-md-12">
                                     <div className="form-group">
                                         <input type="file" name="image" onChange={(e) => setFile(e.target.files[0])} className="form-control" placeholder="image"/>
                                     </div>
                                 </div>
                                 <div className="col-md-12">
                                     <div className="form-group">
                                         <button type="submit" className="btn btn-primary">Post</button>
                                     </div>
                                 </div>
                                 
                             </div>
                         </div>
                     </div>
                 </form>
                 </div>
                 </div>
            </>
        )
    
}
export default AdminPostForm