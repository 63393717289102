import React from "react";
import Axios from "axios";
import Moment from 'moment'
import { useEffect,useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import emailjs from "@emailjs/browser"
import { useRef } from 'react';
import swal from "sweetalert";


function AdminMessageFormEmail (){
    const { messageid } = useParams();
    const [ name , setName] = useState('')
    const [ email , setEmail] = useState('')
    const [ message, setMessage] = useState('')
    const [ subject, setSubject] = useState('')
    const date = Moment().format('YYYY-MM-DD')
    const [ messaged, setData] = useState([])
    const form = useRef();
    const Navigate = useNavigate();

    //let formData = new FormData();
    useEffect(()=>{
        Axios.get(`https://www.epic.org.rw/backend/getmessageid.php?id=${messageid}`)
        .then( res =>{
           setData(res.data)
        }).catch(err => {
            console.log(err)
        })
    },[])

    const onSubmit = (e) =>{
        e.preventDefault()
        emailjs.sendForm('service_aigbvbh', 'template_4u2jt1j', form.current, 'DQaM6QSCYUZ1Petl-')
        .then(
            (result) => {
              console.log(result.text);
              swal("Good job!", "Email sent Successfully!", "success");
              Navigate(`adminmessageemail/${messageid}`);
              setMessage(()=>"");
              setSubject(()=>"");
              
            },
            (error) => {
              console.log(error.text);
              swal("try again!", "Email was not sent Successfully!", "danger");
            Navigate(`adminmessageemail/${messageid}`);
			setMessage(()=>"");
            setSubject(()=>"");
            }
          );
    }
        return(
            <>
               <div id="fh5co-contact" className="">
             <div className="container">
                <br/>
               <br/>
               
                 <form ref={form} onSubmit={onSubmit}>
                     <div className="row">
                         <div className="col-md-6">
                             <h3 className="section-title" style={{marginTop:'15px'}}>Respond Email</h3>
                         </div>
                         <div className="col-md-6">
                          <div className="row"> 
                             <div className="col-md-6">
									<div className="form-group">
										<input type="text" name="name" id="name" onChange={(e) => setName(e.target.value)} defaultValue={messaged.name} className="form-control" placeholder="Name"/>
									</div>
								</div>
                                <div className="col-md-6">
									<div className="form-group">
										<input type="text" name="email" id="email" onChange={(e) => setEmail(e.target.value)} defaultValue={messaged.email} className="form-control" placeholder="Email"/>
									</div>
								</div>
                                <div className="col-md-6">
									<div className="form-group">
										<input type="text" name="subject" id="subject" onChange={(e) => setSubject(e.target.value)} value={subject} className="form-control" placeholder="Subject"/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<textarea name="message" id="email_body" onChange={(e) => setMessage(e.target.value)} value={message} className="form-control" cols="30" rows="7" placeholder="Email Body"></textarea>
									</div>
								</div>
                                 <div className="col-md-12">
                                     <div className="form-group">
                                         <button type="submit" className="btn btn-primary">Send Email</button>
                                     </div>
                                 </div>
                                 
                             </div>
                         </div>
                     </div>
                 </form>
                 </div>
                 </div>
            </>
        )
    
}
export default AdminMessageFormEmail