import React from 'react';
import "../App.css"
const Allvideopage = ({posts,loading}) =>{
    if(loading){
        return <h2>Loading...</h2>
    }
    return (
        <div className="row row-bottom-padded-md">
					<div className="col-md-12">
						<ul id="fh5co-portfolio-list">
						{
                    posts.map(post =>(
							<li className="one-third" data-animate-effect="fadeIn" key={post.videoid}>
								<a href={`/videodetails/${post.videoid}`} className="color-3">
									<div className="case-studies-summary">
                                    <p><video src={post.file} height={400}/></p>
									<span>{post.name}</span>
									</div>
								</a>
							</li>
	                     ))}
						</ul>		
					</div>
				</div>
    )
}

export default Allvideopage;