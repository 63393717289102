import Axios from "axios";
import React from "react";
import Moment from 'moment'
import { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdminVideoForm from "./adminvideoform";
import AllAdminVideopage from "./adminvideopage";
import Paginationnews from "./paginationnews";

function AdminVideo() {
    let Navigate = useNavigate();
    const [ videod, setData] = useState([])
    const [ show, setShow] = useState(false)
    const [ list, setList] = useState("");
    const [loading, setLoading]=useState(false)
    const [currentPage, setCurrentPage]=useState(1)
    const [postPerPage]=useState(10)

    useEffect(()=>{
        Axios.get('https://www.epic.org.rw/backend/getvideo.php')
        .then( res =>{
           setData(res.data)
           setLoading(false)
        }).catch(err => {
            console.log(err)
        })
      
        
    },[])

    
       
            const indexOfLastPost = currentPage * postPerPage;
            const indexOfFirstPost = indexOfLastPost - postPerPage;
            const currentPosts = videod.slice(indexOfFirstPost,indexOfLastPost);
        
            const paginate = (pageNumber) =>{
                setCurrentPage(pageNumber)
            }
            const paginationNext = (pageNumber) =>{
                setCurrentPage(pageNumber+1)
            }
            const paginationPrevious = (pageNumber) =>{
                setCurrentPage(pageNumber-1)
            }


        return(
            
            <>
            <div id="fh5co-contact" className="">
            <div className="container">
            <div className="row">
            <div className="col-md-12">
            <button type="submit" onClick={() => setShow(true)} className="btn btn-primary">Create Video</button>
                                     
                                     {
                                     show ? <AdminVideoForm/>:null
                                     }
    
            </div>
                         <div className="col-md-12">
    <AllAdminVideopage  videod={currentPosts} loading={loading}/>
     <Paginationnews 
            currentPage={currentPage}
            postsPerPage={postPerPage} 
            totalPosts={videod.length}
            paginate={paginate}
            paginateNext={paginationNext}
            paginatePrevious={paginationPrevious}
            />
 </div>

 
 </div>
 </div>
 </div>
         </>
        )
    
}
export default AdminVideo