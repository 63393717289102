import React from "react";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
import Axios from "axios";
import Allgallerypage from "./gallerypage";
import Paginationnews from "./paginationnews";

function GalleryComponent (){
	const [ posts ,setData]=useState([])
        const [loading, setLoading]=useState(false)
        const [currentPage, setCurrentPage]=useState(1)
        const [postPerPage]=useState(10)

		useEffect(()=>{
			Axios.get('https://www.epic.org.rw/getpost.php')
			.then(res => {
				setData(res.data);
				setLoading(false)
			})
		},[])
	
		const indexOfLastPost = currentPage * postPerPage;
		const indexOfFirstPost = indexOfLastPost - postPerPage;
		const currentPosts = posts.slice(indexOfFirstPost,indexOfLastPost);
	
		const paginate = (pageNumber) =>{
			setCurrentPage(pageNumber)
		}
		const paginationNext = (pageNumber) =>{
			setCurrentPage(pageNumber+1)
		}
		const paginationPrevious = (pageNumber) =>{
			setCurrentPage(pageNumber-1)
		}
	 

        return(
            <>
            <div id="fh5co-portfolio">
			<div className="container">

				<div className="row">
					<div className="col-md-6 col-md-offset-3 text-center heading-section">
					<Link to="/gallery"><h3>Our Gallery</h3></Link>
						{/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Velit est facilis maiores, perspiciatis accusamus asperiores sint consequuntur debitis.</p> */}
					</div>
				</div>
				<Allgallerypage posts={currentPosts} loading={loading}/>
            <Paginationnews 
            currentPage={currentPage}
            postsPerPage={postPerPage} 
            totalPosts={posts.length}
            paginate={paginate}
            paginateNext={paginationNext}
            paginatePrevious={paginationPrevious}
            />
			</div>
		</div>
            </>
        )
    }
export default GalleryComponent;