import React from 'react';
import { useState } from 'react';
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebookF,faInstagram,faTwitter } from '@fortawesome/free-brands-svg-icons';


const AllAdminVideopage = ({videod,loading}) =>{
    const [ list, setList] = useState("");
    const [ video, setData] = useState([]);
    let Navigate = useNavigate();

    function onDelete(id){
        //console.log(id)
        Axios.delete(`https://www.epic.org.rw/backend/deletevideo.php?id=${id}`)
        .then( res =>{
           setData(res.data)
           swal("Good job!", "Video Deleted Successfully!", "success");
           Navigate('/adminnews');
        }).catch(err => {
            console.log(err)
            swal("try again!", "Video was not Deleted Successfully!", "danger");
            Navigate("/adminvideo");
        })
   
    }
    if(loading){
        return <h2>Loading...</h2>
    }
    return (
        <>
        <input type="text" placeholder="search..." onChange={(event) => {setList(event.target.value);}} className="form-control" style={{marginTop: "10px"}} />
        <table id="example" className="table table-striped table-bordered" data-show-pagination-switch="true" style={{marginTop: "10px"}}>
    <thead>
        <tr>
            <th>Name</th>
            <th>File</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
            {
                   videod.filter((video) => {
                    if(list === ""){
                        return video;
                    }
                    else if(video.name.toLowerCase()
                            .includes(list.toLowerCase())){
                        return video;
                    }

                }).map(video => (
        <tr key={video.videoid}>
            <td>{video.name}</td>
            <td><video className="img-responsive" src={video.file} alt="" height={10} width={20}/></td>
            <td><button onClick={() => onDelete(video.videoid)} className="btn btn-primary" title='Delete'><FontAwesomeIcon icon="fa fa-trash"/></button></td>
        
        </tr>
            ))
                }
				
                </tbody>
 </table> 
 </>
    );
}

export default AllAdminVideopage;