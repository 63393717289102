import React from "react";
import Axios from "axios";
import Moment from 'moment'
import { useEffect,useState } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function AdminProgramForm (){
    const [ name , setTitle] = useState('')
    const [ description , setDescription] = useState('')
    const [ status, setStatus] = useState('')
    const Navigate = useNavigate();
    const date = Moment().format('YYYY-MM-DD')

    const onSubmit = (e) =>{
        e.preventDefault()

         Axios.post('https://www.epic.org.rw/backend/insertprogram.php',{
           name:name,description:description,status:status
         },{headers:{
            'Content-Type': 'multipart/form-data'
         }})
        .then( res =>{
           console.log(res.data)
           swal("Good job!", "Program Posted Successfully!", "success");
           Navigate('/adminprogram');
           setTitle(()=>"");
           setDescription(()=>"");
           setStatus(()=>"");
        }).catch(err => {
            console.log(err)
            swal("try again!", "Program was not Posted Successfully!", "danger");
            Navigate('/adminprogram');
			setTitle(()=>"");
			setDescription(()=>"");
            setStatus(()=>"");
        })
    }
        return(
            <>
               <div id="fh5co-contact" className="">
             <div className="container">
               
                 <form onSubmit={onSubmit}>
                     <div className="row">
                         <div className="col-md-6">
                             <h3 className="section-title" style={{marginTop:'15px'}}>Add Program</h3>
                         </div>
                         <div className="col-md-6">
                          <div className="row"> 
                             <div className="col-md-6">
									<div className="form-group">
										<input type="text" name="name" onChange={(e) => setTitle(e.target.value)} value={name} className="form-control" placeholder="Title"/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<textarea name="details" onChange={(e) => setDescription(e.target.value)} value={description} className="form-control" cols="30" rows="7" placeholder="Details"></textarea>
									</div>
								</div>
                                 <div className="col-md-12">
                                     <div className="form-group">
                                         <select name="image" onChange={(e) => setStatus(e.target.value)} className="form-control" placeholder="image">
                                            <option value={1}>Pending</option>
                                            <option value={0}>Finished</option>
                                         </select>
                                     </div>
                                 </div>
                                 <div className="col-md-12">
                                     <div className="form-group">
                                         <button type="submit" className="btn btn-primary">Create Program</button>
                                     </div>
                                 </div>
                                 
                             </div>
                         </div>
                     </div>
                 </form>
                 </div>
                 </div>
            </>
        )
    
}
export default AdminProgramForm