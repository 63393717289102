import React from 'react';
import "../App.css"

const Allgallerypage = ({posts,loading}) =>{
    if(loading){
        return <h2>Loading...</h2>
    }
    return (
        <div className="row row-bottom-padded-md">
					<div className="col-md-12">
						<ul id="fh5co-portfolio-list">
						{
                    posts.map(post =>(
							<a href={`/${post.file}`}><li className="one-third" data-animate-effect="fadeIn" style={{ backgroundImage: `url(${post.file})` }}>
								<a href="#" className="color-3">
									<div className="case-studies-summary">
										<span className="fifty-chars">{post.title}</span>
									</div>
								</a>
							</li>
							</a>
	                     ))}
						</ul>		
					</div>
				</div>
    )
}

export default Allgallerypage;