import React from "react";
import logo from '../frontend/coverbggg.jpg'; 
import Axios from 'axios';
import emailjs from "@emailjs/browser"
import { useParams, useNavigate} from 'react-router-dom'
import { useState, useEffect,useRef } from 'react';
import swal from "sweetalert";


function CommentComponentDetails (){
   const { commentid } = useParams();
   const [ posts, setData] = useState([])
   const [ show, setShowComment] = useState(false)
   const [ email, setEmail] = useState('')
   const [ name, setName] = useState('name')
   const [ postj, setPostid ] = useState(commentid)
   const [ comment, setComment] = useState('')
   const [ message, setMessage] = useState('')
   const [ subject, setSubject] = useState('')
   const form = useRef();
   const Navigate = useNavigate();
   

    useEffect(()=>{
        Axios.get(`https://www.epic.org.rw/backend/getcommentid.php?id=${commentid}`)
        .then(res => {
            setData(res.data)
        })
        
    },[])

    const onSubmit = (e) =>{
        e.preventDefault()
        emailjs.sendForm('service_aigbvbh', 'template_4u2jt1j', form.current, 'DQaM6QSCYUZ1Petl-')
        .then(
            (result) => {
              console.log(result.text);
              swal("Good job!", "Email sent Successfully!", "success");
              setMessage(()=>"");
              setSubject(()=>"");
              
            },
            (error) => {
              console.log(error.text);
              swal("try again!", "Email was not sent Successfully!", "danger");
			setMessage(()=>"");
            setSubject(()=>"");
            }
          );
    }
        return(
            <>
		
		<div id="fh5co-blog-section" className="fh5co-section-gray">
			<div className="container">
				<div className="row">
					<div className="col-md-8 col-md-offset-2 text-center heading-section">
                    <h2><strong>{posts.postid} </strong></h2>
                        <h3>{posts.email}</h3>
						<p>{posts.comment}</p>
					</div>
				</div>
			</div>
			<div className="container">
            <button type="submit" onClick={() => setShowComment(true)} className="btn btn-primary">Respond</button>
                {
                    show ? 
                    <div id="fh5co-contact" className="">
                    <div className="container">
                        <form ref={form} onSubmit={onSubmit}>
                            <div className="row">
                            <div className="col-md-6"></div>
                                <div className="col-md-6">
                                <div className="row">
                                <div className="col-md-6">
									<div className="form-group">
										<input type="text" name="email" id="email" onChange={(e) => setEmail(e.target.value)} defaultValue={posts.email} className="form-control" placeholder="Email"/>
									</div>
								</div>
                                <div className="col-md-6">
									<div className="form-group">
										<input type="text" name="subject" id="subject" onChange={(e) => setSubject(e.target.value)} value={subject} className="form-control" placeholder="Subject"/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<textarea name="message" id="email_body" onChange={(e) => setMessage(e.target.value)} value={message} className="form-control" cols="30" rows="7" placeholder="Email Body"></textarea>
									</div>
								</div>
                                 <div className="col-md-12">
                                     <div className="form-group">
                                         <button type="submit" className="btn btn-primary">Send Email</button>
                                     </div>
                                 </div>
                                 
                             </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> 
                 : null
                 }

			</div>
		</div>
		

            </>
        )
    }

export default CommentComponentDetails;