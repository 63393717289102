import React from "react";
import Axios from "axios"
import logo from '../frontend/coverbggg.jpg';
export class ProgramStatusPendingComponent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            posts:[]
        }
    }
    

    componentDidMount(){
        Axios.get('https://www.epic.org.rw/getrunningpro.php')
        .then(res => {
            this.setState({
                posts: res.data
            });
        })
    }
    render(){
        return(
            <>
            
		
		<div id="fh5co-blog-section" className="fh5co-section-gray">
			<div className="container">
				<div className="row">
					<div className="col-md-8 col-md-offset-2 text-center heading-section">
						<h3>Our Programs</h3>
				    </div>
				</div>
			</div>
			<div className="container">
            <div className="row row-bottom-padded-md">
                {
                    this.state.posts.slice(0, 3).map(program =>(
                       <div className="col-lg-4 col-md-4 col-sm-6" key={program.programid}>
                            <div className="fh5co-blog">
                            <a href={`/programdetails/${program.programid}`}><img className="img-responsive" src={logo} alt=""/></a>
                                <div className="blog-text">
                                    <div className="prod-title">
                                        <h3><a href={`/newsdetails/${program.programid}`}>{program.title}</a></h3>
                                        <span>{program.status == "1" ? "Running" : "Completed"}</span>
                                        <p>{program.name}</p>
                                        <p><a href={`/programdetails/${program.programid}`}>Learn More...</a></p>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    

                    )
                        )
                }
				
                </div>
				<div className="row">
					<div className="col-md-4 col-md-offset-4 text-center ">
						<a href="/allprogramrunning" className="btn btn-primary btn-lg">Learn More</a>
					</div>
				</div>

			</div>
		</div>
		

            </>
        )
    }
}