import React from "react"; 
import { Link, useNavigate } from "react-router-dom";
import logo from '../frontend/coverbggg.jpg';
import {connect} from 'react-redux';
import { useState } from "react";
import * as actions from "../store/action/auth";

function LoginComponent (props){
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	let Navigate = useNavigate();

    const onSubmit = (e) =>{
        e.preventDefault();
        //console.log(username+ " " +password)
		props.onAuth(username,password)
		Navigate('/admin')
    }
        return(
            <>
           <div id="fh5co-content-section" className="fh5co-section-gray">
			<div className="container">
				<div className="row"  style={{ marginLeft: '200px'}}>
					<div className="col-md-8">
						<div className="fh5co-team text-center">
							<figure>
								<img src={logo}  alt="user"/>
							</figure>
							<div className="contact-info">
				<h2>Admin Log In</h2>
			</div>
			<hr/>
			<form onSubmit={onSubmit}>

			
							<div className="row">
		<div className="col-md-12">
			<div className="contact-form">
				<div className="form-group">
				  <div className="col-md-12" style={{marginBottom: '15px'}}>          
					<input type="text" onChange={(e) => setUsername(e.target.value)} value={username} className="form-control" placeholder="Enter User Name" name="username"/>
				  </div>
				</div>
				<div className="form-group">
				 <div className="col-md-12" style={{marginBottom: '25px'}}>          
					<input type="password" className="form-control" onChange={(e) =>setPassword(e.target.value)} value={password} placeholder="Enter Password" name="password"/>
				  </div>
				</div>
				<div className="contact-info">
               {/* <label className="form-check-label">
                    Don't have an account!! 
                    </label><p><Link to="/register">Register</Link></p> */}
                    
			</div>
				<div className="form-group">        
				  <div className="col-sm-offset-4 col-md-10">
					<button type="submit" className="btn btn-default">Login</button>
				  </div>
				</div>
			</div>
		</div>
	</div>
	</form>
						</div>
					</div>
				
				</div>
			</div>
		</div>
            </>
        )
    }


const mapStateToProps = (state) =>{
	return{
		loading: state.loading,
		error: state.error
	}
}

const mapDispatchToProps = (dispatch) => {
	return{
		onAuth: (username,password) => dispatch(actions.authLogin(username,password))
	}

}

export default connect(mapStateToProps,mapDispatchToProps)(LoginComponent)