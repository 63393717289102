import Axios from "axios";
import React from "react";

import Moment from 'moment'
import { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import AdminProgramForm from "./adminprogramform";
import AllAdminProgrampage from "./adminprogrampage";
import Paginationnews from "./paginationnews";

// import BootStrapTable from "react-bootstrap-table-next";
// import Paginator from "react-bootstrap-table2-paginator";
// import Filter,{ textFilter } from "react-bootstrap-table2-filter";
// import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css"

function AdminProgram() {
    let Navigate = useNavigate();
    const [ programd, setData] = useState([])
    const [list, setList] = useState("");
    const [ show, setShow] = useState(false)
    const [loading, setLoading]=useState(false)
    const [currentPage, setCurrentPage]=useState(1)
    const [postPerPage]=useState(10)

    useEffect(()=>{
        Axios.get('https://www.epic.org.rw/backend/getprogram.php')
        .then( res =>{
           setData(res.data)
        }).catch(err => {
            console.log(err)
        })
        
    },[])

   
            const indexOfLastPost = currentPage * postPerPage;
            const indexOfFirstPost = indexOfLastPost - postPerPage;
            const currentPosts = programd.slice(indexOfFirstPost,indexOfLastPost);
        
            const paginate = (pageNumber) =>{
                setCurrentPage(pageNumber)
            }
            const paginationNext = (pageNumber) =>{
                setCurrentPage(pageNumber+1)
            }
            const paginationPrevious = (pageNumber) =>{
                setCurrentPage(pageNumber-1)
            }
            // const columns = [
            //     {dataField:'name',text:"Title",sort:true,filter:textFilter()},
            //     {dataField:'description',text:"Description",sort:true},
            //     {dataField:'description',text:"Description",sort:true}
            // ]
        
            // const pagination =  Paginator({
            //     page:1,
            //     sizePerPage:10,
            //     lastPageText:">>",
            //     firstPageText:"<<",
            //     nextPageText:">",
            //     prePageText:"<",
            //     showTotal:true,
            //     alwaysShowAllBtns:true,
            // });

        return(
            
            <>
            <div id="fh5co-contact" className="">
            <div className="container">
            <div className="row">
            <div className="col-md-12">
            <button type="submit" onClick={() => setShow(true)} className="btn btn-primary">Create Program</button>
                                     
                                     {
                                     show ? <AdminProgramForm/>:null
                                     }
    
            </div>
                         <div className="col-md-12">
                         <AllAdminProgrampage programd={currentPosts} loading={loading}/>
     <Paginationnews 
            currentPage={currentPage}
            postsPerPage={postPerPage} 
            totalPosts={programd.length}
            paginate={paginate}
            paginateNext={paginationNext}
            paginatePrevious={paginationPrevious}
            />
 {/* <BootStrapTable
 keyField="name"
 data={programd}
 columns={columns}
 pagination={Paginator()}
 filter={Filter()}
 /> */}
 </div>

 
 </div>
 </div>
 </div>
         </>
        )
    
}
export default AdminProgram