import Axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
// import { useAlert } from 'react-alert'
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';


import logo from '../frontend/coverbggg.jpg'; 
function ContactComponent(){
	const [name,setName] = useState("")
	const [email,setEmail] = useState("")
	const [comment,setComment] = useState("")
	const Navigate = useNavigate();

	const onSubmit =(e)=>{
		e.preventDefault();
		Axios.post('https://www.epic.org.rw/insertmessage.php',{
			name:name,
			email:email,
			comment:comment
		},{headers:{
            'Content-Type': 'multipart/form-data'
         }}).then( res =>{
			//console.log(res.data);
			swal("Thank You!", "Message sent Successfully!", "success");
			Navigate('/contact');
			setComment(()=>"");
			setEmail(()=>"")
			setName(()=>"")
			  
		}).catch(err => {
			//console.log(err)
			swal("Try Again!", "Message was not sent Successfully!", "danger");
			Navigate('/contact');
			setComment(()=>"");
			setEmail(()=>"")
			setName(()=>"")
		});
		
	}
	
    
        return(
            <>
		
		<div id="fh5co-contact" className="">
			<div className="container">
				<form onSubmit={onSubmit}>
					<div className="row">
						<div className="col-md-6">
							<h3 className="section-title">Our Address</h3>
							<p></p>
							<ul className="contact-info">
								{/* <li><i className="icon-location-pin"></i>KN st 123 </li> */}
								<li><i className="icon-phone2"></i>+250 781786081</li>
								<li><i className="icon-mail"></i><a href="#">info@epic.org.rw</a></li>
								<li><i className="icon-globe2"></i><a href="#">www.epic.org.rw</a></li>
							</ul>
						</div>
						<div className="col-md-6">
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<input type="text" name="name" onChange={(e)=>setName(e.target.value)} value={name} className="form-control" placeholder="Name" required/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<input type="email" name="email" onChange={(e)=>setEmail(e.target.value)} value={email} className="form-control" placeholder="Email" required/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<textarea name="comment" onChange={(e)=>setComment(e.target.value)} value={comment} className="form-control" cols="30" rows="7" placeholder="Message"></textarea>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<button type="submit" className="btn btn-primary">Send Message</button>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
            </>
        )
}
export default ContactComponent;
