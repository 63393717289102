import React from 'react';
import { useState } from 'react';
import Axios from "axios";
import "../App.css"
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AllAdminCommentpage = ({commentd,loading}) =>{
    const [ list, setList] = useState("");
    const [ video, setData] = useState([]);
    let Navigate = useNavigate();

    function onDelete(id){
        //console.log(id)
        Axios.delete(`https://www.epic.org.rw/backend/deletecomment.php?id=${id}`)
        .then( res =>{
           setData(res.data)
           swal("Good job!", "Comment Deleted Successfully!", "success");
           Navigate('/admincomment');
        }).catch(err => {
           console.log(err)
           swal("try again!", "Comment was not Deleted Successfully!", "danger");
           Navigate('/admincomment');
        })
    }
    if(loading){
        return <h2>Loading...</h2>
    }
    return (
        <>
              <input type="text" placeholder="search..." onChange={(event) => {setList(event.target.value);}} className="form-control" style={{marginTop: "10px"}} />
     <table id="myTable" className="table table-striped table-bordered" style={{marginTop: "10px"}} >
    <thead>
        <tr>
            <th>Email</th>
            <th>Comment</th>
            <th>Post</th>
            <th></th>
            <th></th>
        </tr>
        </thead>
        <tbody>
            {
                     commentd.filter((comment) => {
                        if(list === ""){
                            return comment;
                        }
                        else if(comment.email.toLowerCase()
                                .includes(list.toLowerCase())){
                            return comment;
                        }else if(comment.comment.toLowerCase()
                        .includes(list.toLowerCase())){
                            return comment;
                        }
    
                    }).map(comment => (
            <tr key={comment.commentid}>
                <td>{comment.email}</td>
               <td className="fifty-chars">{comment.comment}</td>
               <td className="fifty-chars">{comment.postid}</td>
               <td><Link to={`/admincommentdetails/${comment.commentid}`}><button className="btn btn-primary" title='View Comment'><FontAwesomeIcon icon="fa fa-eye"/></button></Link></td>
                <td><button onClick={() => onDelete(comment.commentid)} className="btn btn-primary"><FontAwesomeIcon icon="fa fa-trash"/></button></td>
            </tr>
                ))
            }
        </tbody>
 </table> 
 </>
    );
}

export default AllAdminCommentpage;