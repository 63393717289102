import React from 'react';
import "../App.css"

const Allnewspage = ({posts,loading}) =>{
    if(loading){
        return <h2>Loading...</h2>
    }
    return (
        <div className="row row-bottom-padded-md">
            {
                    posts.map(post =>(
                       <div className="col-lg-3 col-md-3 col-sm-4" key={post.postid}>
                            <div className="fh5co-blog">
                                <a href={`/newsdetails/${post.postid}`}><img className="img-responsive" src={post.file} alt=""/></a>
                                <div className="blog-text">
                                    <div className="prod-title">
                                        <h3><a href={`/newsdetails/${post.postid}`}>{post.title}</a></h3>
                                        <span>{post.post_date}</span>
                                        <p className="fifty-chars">{post.headline}</p>
                                        <p><a href={`/newsdetails/${post.postid}`}>Learn More...</a></p>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    

                    )
                        )
                }
				
        </div>
    );
}

export default Allnewspage;