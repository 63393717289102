import Axios from "axios";
import React from "react";
import Moment from 'moment'
import { useEffect,useState } from "react";

import AdminPostForm from "./adminpostform";
import { Link, useNavigate } from "react-router-dom";
import AllAdminCommentpage from "./admincommentpage";
import Paginationnews from "./paginationnews";


function AdminPostComment() {
    // const { name, headline, details, date, image,} = this.state;
    const [ commentd, setData] = useState([])
    const [ show, setShow] = useState(false)
    const [list, setList] = useState("");
    const [loading, setLoading]=useState(false)
    const [currentPage, setCurrentPage]=useState(1)
    const [postPerPage]=useState(10)

    //let formData = new FormData();

    useEffect(()=>{
        Axios.get('https://www.epic.org.rw/backend/getcomment.php')
        .then( res =>{
           setData(res.data)
        }).catch(err => {
            console.log(err)
        })
    },[]);

            const indexOfLastPost = currentPage * postPerPage;
            const indexOfFirstPost = indexOfLastPost - postPerPage;
            const currentPosts = commentd.slice(indexOfFirstPost,indexOfLastPost);
        
            const paginate = (pageNumber) =>{
                setCurrentPage(pageNumber)
            }
            const paginationNext = (pageNumber) =>{
                setCurrentPage(pageNumber+1)
            }
            const paginationPrevious = (pageNumber) =>{
                setCurrentPage(pageNumber-1)
            }


    return(
            
            <>
            <div id="fh5co-contact" className="">
            <div className="container">
            <div className="row">
            <div className="col-md-12">
          
            </div>
                         <div className="col-md-12">
                         <AllAdminCommentpage commentd={currentPosts} loading={loading}/>
     <Paginationnews 
            currentPage={currentPage}
            postsPerPage={postPerPage} 
            totalPosts={commentd.length}
            paginate={paginate}
            paginateNext={paginationNext}
            paginatePrevious={paginationPrevious}
            />
 </div>

 
 </div>
 </div>
 </div>
         </>
        )
    
}
export default AdminPostComment