import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome, faFacebookF  } from '@fortawesome/free-brands-svg-icons'

library.add(fas, faTwitter, faFontAwesome, faFacebookF )

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render( <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  </React.StrictMode>);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render();
reportWebVitals();

