import {createStore, compose, applyMiddleware} from 'redux';
import { thunk } from 'redux-thunk'
import reducer from './store/reducer/auth'

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSIONS_COMPOSE__ || compose

const store = createStore(reducer,composeEnhances(
applyMiddleware(thunk)
));

export default store;