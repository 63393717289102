import React from "react";
import Axios from "axios";
import Moment from 'moment'
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function AdminVideoForm (){
    const [ title , setTitle] = useState('')
    const date = Moment().format('YYYY-MM-DD')
    const Navigate = useNavigate();
    const [data,setData] = useState({
        name:""
    })

    const [myfile,setMyfile] = useState(null)
    const onchangedata = (e)=>{
    setData({...data,[e.target.name]:e.target.value})
    }
    const onchangefile = (e)=>{
    setMyfile(e.target.files[0])
    }

    const onSubmit = (e) =>{
        e.preventDefault()
        let formData = new FormData();
        formData.append('title', title);
        formData.append('file', myfile);

        // let files = [];
        // let titles = formData.get('title')

         Axios.post('https://www.epic.org.rw/backend/insertvideo.php',formData)
         .then( res =>{
           console.log(res.data)
           swal("Good job!", "Video Posted Successfully!", "success");
           Navigate('/adminvideo');
           setTitle(()=>"");
        }).catch(err => {
            console.log(err)
            swal("try again!", "Video was not Posted Successfully!", "danger");
            Navigate('/adminvideo');
			setTitle(()=>"");
        })
    }
        return(
            <>
               <div id="fh5co-contact" className="">
             <div className="container">
                <br/>
               <br/>
               
                 <form onSubmit={onSubmit} encType="multipart/form-data">
                     <div className="row">
                         <div className="col-md-6">
                             <h3 className="section-title" style={{marginTop:'15px'}}>Add Videos</h3>
                         </div>
                         <div className="col-md-6">
                          <div className="row"> 
                             <div className="col-md-6">
									<div className="form-group">
										<input type="text" name="title" onChange={(e) => onchangedata(e)} value={title} className="form-control" placeholder="Title"/>
									</div>
								</div>
                                 <div className="col-md-12">
                                     <div className="form-group">
                                         <input type="file" name="file" onChange={(e) => onchangefile(e)} className="form-control" placeholder="image"/>
                                     </div>
                                 </div>
                                 <div className="col-md-12">
                                     <div className="form-group">
                                         <button type="submit" className="btn btn-primary">Create Video</button>
                                     </div>
                                 </div>
                                 
                             </div>
                         </div>
                     </div>
                 </form>
                 </div>
                 </div>
            </>
        )
    
}
export default AdminVideoForm