import React, { useState } from "react";
import Axios from "axios";
import ReactPaginate from "react-paginate"
import logo from '../frontend/coverbggg.jpg';
import { useEffect } from "react";

import AllProgramFinishedPage from "./allprogramfinishedpage";
import Paginationprogram from "./paginationprogram";


const AllProgramFinishedComponent = () => {
   
    const [programs, setData] = useState([])
    const [loading, setLoading]=useState(false)
    const [currentPage, setCurrentPage]=useState(1)
    const [postPerPage]=useState(10)


    useEffect(()=>{
        Axios.get('https://www.epic.org.rw/getfinishedpro.php')
        .then(res => {
            setData(res.data)
            setLoading(false)
        })
    })
        const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    const currentPosts = programs.slice(indexOfFirstPost,indexOfLastPost);

    const paginate = (pageNumber) =>{
        setCurrentPage(pageNumber)
    }
    const paginationNext = (pageNumber) =>{
        setCurrentPage(pageNumber+1)
    }
    const paginationPrevious = (pageNumber) =>{
        setCurrentPage(pageNumber-1)
    }
        return(
            <>
            <div className="fh5co-hero">
			<div className="fh5co-overlay"></div>
			<div className="fh5co-cover text-center" data-stellar-background-ratio="0.5" style={{backgroundImage: `url(${logo})`}}>
				<div className="desc">
                <h2>We Are <strong>EPIC</strong></h2>
					<span>(Environmental Promotion and Idyll community)</span>
					<span><a href="/about" className="btn btn-primary btn-lg">About Us</a></span>
				</div>
			</div>

		</div>
		
		<div id="fh5co-blog-section" className="fh5co-section-gray">
			<div className="container">
				<div className="row">
					<div className="col-md-8 col-md-offset-2 text-center heading-section">
						<h3>Our Program</h3>
					</div>
				</div>
			</div>
			<div className="container">
                <AllProgramFinishedPage programs={currentPosts} loading={loading}/>
                <Paginationprogram
            currentPage={currentPage}
            postsPerPage={postPerPage} 
            totalPosts={programs.length}
            paginate={paginate}
            paginateNext={paginationNext}
            paginatePrevious={paginationPrevious}
            />
			</div>
		</div>
		

            </>
        )
}

export default AllProgramFinishedComponent;