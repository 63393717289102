import React from "react";

export class AboutComponent extends React.Component{
    render(){
        return(
            <>
           <div id="fh5co-feature-product" class="fh5co-section-gray">
			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center heading-section">
                    <h3>What is EPIC.</h3>
                <p>EPIC (Environmental Promotion and Idyll community) Is a Non-governmental organization  created with a Target of working hand in hand with the community (Mainly Women; Youth and Children) in developing and implementing project that will create activities aiming the promotion of environment; resilient to climate change and conservation of nature, these activities will be directly increasing the wellbeing of the community through instituting the concept of circular economy in our income generation activities and sustain the environment for the safekeeping of our future generation.  </p>
					</div>
				</div>

				{/* <div className="row row-bottom-padded-md text-center">
               <h3>Background.</h3>
						<p>In 2020 Rwanda population was estimated at 12,952,218 people at midyear according to UN data. Rwanda population is equivalent to 0.17% of the total world population. This means Rwanda is one of highly populated country. Population growth is currently 2.4% per year.
Poverty has been reduced considerably from 60.4% in 2000 to 39% in 2014 and extreme poverty reduced from 40% to 16.3% over the same period (NISR, 2014). Over the last decade, significant growth in agricultural productivity has been one of the main drivers of growth and poverty reduction. 
A vast majority of the poor in Rwanda’s population depends directly on access to natural resources, and they are vulnerable to environment degradation related shocks, such as droughts, heavy rainfall, landslides, floods, lack of access to/or competition over natural resources. 
This factor combined with economic growth exert pressures on the country’s natural resources, most notably on the country’s waters, forests, lands and biodiversity. 
It is now broadly understood that poverty is multidimensional in character; the Human Development Index, introduced in 1990, recognizes the role of environmental health and education in addition to consumption; and the Multidimensional Poverty Index, introduced in 2010, explicitly accounts for complementarities across dimensions of environmental health, education, and several key assets. (UNDP, 2010; Alkire and Foster, 2011). 
Non-Governmental Organizations are civil organizations that are largely or totally independent of government and which function as charitable or religious associations. They mobilize private funds for development initiatives and programs, raise awareness and influence policies in pursuance of the ideals of democracy and good governance as well as undertake diverse humanitarian projects that could better the lots of the grass-roots. ( (Jackline Abakunzi, 2020)
The World Bank (2010), sees non-governmental organizations as private agencies; indigenous groups organized regionally or nationally and member groups in villages that support international and national development and partner with governments in global development initiatives.
</p>
                </div> */}
				<div className="row">
					<div className="col-md-6">
						<div className="feature-text">
							<h3>Mission</h3>
							<p>Empowering and involving community in building lifelong solutions for environmental degradation and creating adaptation system to climate change.</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="feature-text">
							<h3>Vision</h3>
							<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="feature-text">
							<h3>Objectives</h3>
							<p><ul>
                                <li>The Integration of the community mainly women, youth and children as first-class beneficiaries of our project’s activities; </li>
                                <li>Creation and coordination of Environmental clubs in villages and schools to help in community mobilization, in training and in conducting the diagnosis of the status of the environment and the community;</li>
                                <li>The development of research-based solution addressing climate change issues and Environmental degradation issues;</li>
                                <li>Identification and conservation  of species at risk of extinction; </li>
                                <li>Restoration of degraded lands and water sanitation; </li>
                                <li>Initiate and strengthen the collaboration with governmental, non-governmental, private sector and academic institutions interested in environmental protection and community. </li>
                                </ul></p>
						</div>
					</div>
                    <div className="col-md-6">
						<div className="feature-text">
							<h3>Scope of our Work</h3>
							<p>Our work will be centered on the development of projects to implement the findings of research aiming the environment promotion resilient to climate change and also the improvement of the community welfare.</p>
						</div>
					</div>
				</div>

				
			</div>
		</div>
            </>
        )
    }
}