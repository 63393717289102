import React, { useState } from 'react'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminPost from '../components/adminpost'
import AdminProgram from '../components/adminprogram';
import AdminPostFormUpdate from '../components/adminpostformupdate';
import Adminheader from './adminheader'; 
import AdminProgramUpdate from '../components/adminprogramupdate';
import AdminMessage from '../components/adminmessage';
import AdminMessageFormEmail from '../components/adminmessageemail';
import AdminVideo from '../components/adminvideo';
import AdminPostComment from '../components/admincomment';
import CommentComponentDetails from '../components/admincommentdetails';
import { Home } from '../components/home';

export class AdminLayout extends React.Component{
 render(){
    return (
      <>
      <Router forceRefresh={true}>
        <Adminheader />
      <Routes>
        <Route path="/admin" element={<AdminPost />}/>
        <Route path="/adminupdate/:postid" element={<AdminPostFormUpdate />}/>
        <Route path="/adminprogram" element={<AdminProgram />}/>
        <Route path="/adminprogramupdate/:programid" element={<AdminProgramUpdate />}/>
        <Route path="/adminnews" element={<AdminPost />}/>
        <Route path="/adminmessage" element={<AdminMessage />}/>
        <Route path="/adminmessageemail/:messageid" element={<AdminMessageFormEmail />}/>
        <Route path="/adminvideo" element={<AdminVideo />}/>
        <Route path="/admincomment" element={<AdminPostComment />}/>
        <Route path="/admincommentdetails/:commentid" element={<CommentComponentDetails/>}/>
      </Routes>
    </Router>
      </>
    );
        }
    }