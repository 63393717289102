import React from 'react';
import { Link } from 'react-router-dom';
import {connect} from "react-redux";
import { useNavigate } from 'react-router-dom';
import * as actions from "../store/action/auth";

function Adminheader(props){
	let Navigate = useNavigate();
	const onSubmit = (e) =>{
        e.preventDefault();
        //console.log(username+ " " +password)
		props.Logout()
		Navigate('/')
    }
    return (
        <header id="fh5co-header-section" className="sticky-banner fh5co-link">
					
				<div className="container">
					<div className="nav-header">
						<Link to="#" className="js-fh5co-nav-toggle fh5co-nav-toggle dark"><i></i></Link>
						<h1 id="fh5co-logo"><a href="index.html">EPIC Admin</a></h1>
					
						<nav id="fh5co-menu-wrap" role="navigation">
							<ul className="sf-menu" id="fh5co-primary-menu">
								<li><Link to="/admin">Home</Link></li>
								<li><Link to="/adminmessage">Messages</Link></li>
								<li><Link to="/adminnews">News</Link></li>
								<li><Link to="/adminprogram">Program</Link></li>
								<li><Link to="/adminvideo">Video</Link></li>
								<li><Link to="/admincomment">Comment</Link></li>
								<li><Link to="#" onClick={onSubmit}>Logout</Link></li>
							</ul>
						</nav>
					</div>
				</div>
			</header>
    );
}
const mapStateToProps = (state) =>{
	return{
		loading: state.loading,
		error: state.error
	}
}
const mapDispatchToProps = (dispatch) => {
	return{
		Logout: () => dispatch(actions.logout())
	}

}

export default connect(mapStateToProps,mapDispatchToProps)(Adminheader)