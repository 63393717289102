import React from "react";
import { Link } from "react-router-dom";
import { Slide, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import Axios from "axios";

import { MessageCompent } from "./message";

export class Home extends React.Component{
	constructor(props){
        super(props);
        this.state = {
            posts:[],
			postss:[]
        }
    }
    

    componentDidMount(){
        Axios.get('https://www.epic.org.rw/getpost.php')
        .then(res => {
            this.setState({
                posts: res.data
            });
			// console.log(res.data)
        })
		Axios.get('https://www.epic.org.rw/getvideo.php')
        .then(res => {
            this.setState({
                postss: res.data
            });
        })
    }
    render(){
		
        return(
            
            <>
			 <div className="container">
        <div className="fh5co-hero">
			<div className="fh5co-overlay"></div>
			<Zoom>
			{
                    this.state.posts.slice(0, 6).map(post =>(
			<div key={post.postid} className="fh5co-cover text-center" style={{ backgroundImage: `url(${post.file})` }}>
<div className="desc">
				<h2>We Are <strong>EPIC</strong></h2>
					<span>(Environmental Promotion and Idyll community)</span>
					<span><a href="/about" className="btn btn-primary btn-lg" style={{backgroundColor: "#228B22"}}>About Us</a></span>
				</div>
			</div>
	        ))}
			</Zoom>
		</div>
		<div id="fh5co-portfolio" >
		<div className="row">
					<div className="col-md-12 text-center heading-section">
                    <h3>What is EPIC.</h3>
                <p>EPIC (Environmental Promotion and Idyll community) Is a Non-governmental organization  created with a Target of working hand in hand with the community (Mainly Women; Youth and Children) in developing and implementing project that will create activities aiming the promotion of environment; resilient to climate change and conservation of nature, these activities will be directly increasing the wellbeing of the community through instituting the concept of circular economy in our income generation activities and sustain the environment for the safekeeping of our future generation.  </p>
					</div>
				</div>
			<div className="container">

				<div className="row">
					<div className="col-md-6 col-md-offset-3 text-center heading-section">
					<h3>Our Gallery</h3>
					</div>
				</div>

				
				<div className="row row-bottom-padded-md">
					<div className="col-md-12">
						<ul id="fh5co-portfolio-list">
						{
                    this.state.posts.slice(0, 6).map(post =>(
							<li key={post.postid} className="one-third" data-animate-effect="fadeIn" style={{ backgroundImage: `url(${post.file})` }}>
								<a href="#"  className="color-3">
									<div className="case-studies-summary">
										<span>{post.title}</span>
										{/* <h2>Donation is caring</h2> */}
									</div>
								</a>
							</li>
	                     ))}
						</ul>	
					
					</div>
				</div>

				<div className="row">
					<div className="col-md-6 col-md-offset-3 text-center heading-section">
					<h3>Our Videos</h3>
					</div>
				</div>

				
				<div className="row row-bottom-padded-md">
					<div className="col-md-12">
						<ul id="fh5co-portfolio-list">
						{
                    this.state.postss.slice(0, 3).map(postsi =>(
							<li key={postsi.videoid} className="one-third" data-animate-effect="fadeIn">
								<a href={`/videodetails/${postsi.videoid}`} className="color-3">
									<div className="case-studies-summary">
										<p><video src={postsi.file} height={400} /></p>
										<span>{postsi.name}</span>
									</div>
								</a>
							</li>
	                     ))}
						</ul>	
					
					</div>
				</div>


				<div className="row">
					<div className="col-md-4 col-md-offset-2 text-center">
						<Link to="/gallery" className="btn btn-primary btn-lg" style={{backgroundColor: "#228B22"}}>See Gallery</Link>
					</div>
					<div className="col-md-4 text-center">
						<Link to="/video" className="btn btn-primary btn-lg" style={{backgroundColor: "#228B22"}}>See Videos</Link>
					</div>
				</div>

				
			</div>
		</div>
		{/* <MessageCompent/> */}
		</div>
            </>
        )
    }
}