import Axios from "axios";
import React from "react";

import Moment from 'moment'
import { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AllAdminMessagepage from "./adminmessagepage";
import Paginationnews from "./paginationnews";

function AdminMessage() {
    let Navigate = useNavigate();
    // const { name, headline, details, date, image,} = this.state;
    const [ messaged, setData] = useState([])
    const [list, setList] = useState("");
    const [ show, setShow] = useState(false)
    const [loading, setLoading]=useState(false)
    const [currentPage, setCurrentPage]=useState(1)
    const [postPerPage]=useState(10)

    useEffect(()=>{
        Axios.get('https://www.epic.org.rw/backend/getmessage.php')
        .then( res =>{
           setData(res.data)
           setLoading(false)
        }).catch(err => {
            console.log(err)
        })
        
    },[])

    function onDelete(id){
        //console.log(id)
        Axios.delete(`https://www.epic.org.rw/backend/deletemessage.php?id=${id}`)
        .then( res =>{
           setData(res.data)
        }).catch(err => {
            console.log(err)
        })
        Navigate("/adminmessage")
            }


            const indexOfLastPost = currentPage * postPerPage;
            const indexOfFirstPost = indexOfLastPost - postPerPage;
            const currentPosts = messaged.slice(indexOfFirstPost,indexOfLastPost);
        
            const paginate = (pageNumber) =>{
                setCurrentPage(pageNumber)
            }
            const paginationNext = (pageNumber) =>{
                setCurrentPage(pageNumber+1)
            }
            const paginationPrevious = (pageNumber) =>{
                setCurrentPage(pageNumber-1)
            }


        return(
            
            <>
            <div id="fh5co-contact" className="">
            <div className="container">
            <div className="row">
                         <div className="col-md-12">
                         <AllAdminMessagepage  messaged={currentPosts} loading={loading}/>
     <Paginationnews 
            currentPage={currentPage}
            postsPerPage={postPerPage} 
            totalPosts={messaged.length}
            paginate={paginate}
            paginateNext={paginationNext}
            paginatePrevious={paginationPrevious}
            />
 </div>

 
 </div>
 </div>
 </div>
         </>
        )
    
}
export default AdminMessage